import React from 'react';
import autoBind from 'react-autobind';
import { Table, Button } from 'antd';
//
import CustomComponent from '../../components/CustomComponent';
import Utils from '../../components/Utils';
import config from '@/config/config';
//
import '../../assets/stylesheets/AdminSearchUsersView.less';
//
export default class CommonAssignmentsTable extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { sortedInfo: { columnKey: 'createdOn', order: 'descend' }, assignments: [] };
  }

  //Table support
  handleFilterChange(pagination, filters, sorter) {
    this.setState({ sortedInfo: sorter, filters });
  }

  getTableRowProps(record) {
    return {
      onClick: () => this.props.onRow && this.props.onRow(record),
    };
  }

  //UI
  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};

    const columns = [
      {
        title: 'Date', dataIndex: 'createdOn', key: 'createdOn',
        render: createdOn => createdOn ? Utils.getDateOnUIFormatByTimestamp(createdOn) : '',
        sorter: (a, b) => a.createdOn - b.createdOn,
        sortOrder: sortedInfo.columnKey === 'createdOn' && sortedInfo.order,
      },
      {
        title: 'Name', dataIndex: 'assignedTo', key: 'assignedTo',
        sortOrder: sortedInfo.columnKey === 'assignedTo' && sortedInfo.order,
        sorter: (a, b) => a.assignedTo.localeCompare(b.assignedTo),
      },
      {
        title: 'Email', dataIndex: 'assignedToEmail', key: 'assignedToEmail',
        sortOrder: sortedInfo.columnKey === 'assignedToEmail' && sortedInfo.order,
        sorter: (a, b) => a.assignedToEmail.localeCompare(b.assignedToEmail),
      },
      {
        title: 'Assigned key', key: 'assignedActivationKey',
        sortOrder: sortedInfo.columnKey === 'assignedActivationKey' && sortedInfo.order,
        sorter: (a, b) => a.assignedActivationKey.localeCompare(b.assignedActivationKey),
        render: props => props?.assignedActivationKey ? (
          <a type="link" href={this.props.app.urlManager.parseUrl(config.ApplicationRoutes.adminLicensesView, null, props.assignedActivationKey)} target="_blank" rel="noopener noreferrer">
            {props.assignedActivationKey}
          </a>
        ) : '-',
      },
      {
        title: 'Activated On', dataIndex: 'activatedOn', key: 'activatedOn',
        render: activatedOn => activatedOn !== -1 ? Utils.getDateOnUIFormatByTimestamp(activatedOn) : '-',
        sorter: (a, b) => a.activatedOn - b.activatedOn,
        sortOrder: sortedInfo.columnKey === 'activatedOn' && sortedInfo.order,
      },
    ];

    const props = {
      loading: this.props.isLoading, onChange: this.handleFilterChange,
      locale: { emptyText: 'No Assignments found!' },
      pagination: { pageSize: 50, hideOnSinglePage: true, showSizeChanger: false, position: ['bottomCenter'] },
    };
    return (<Table columns={columns} dataSource={this.props.assignments} onRow={this.getTableRowProps} {...props} />);
  }
}