import React from 'react';
import {
  CalendarOutlined,
  ScheduleOutlined,
  CreditCardOutlined,
  GiftOutlined,
  ContactsOutlined,
} from '@ant-design/icons';
import { FaBuilding } from 'react-icons/fa';
import { GiVideoConference } from 'react-icons/gi';
import { HiOutlineStatusOnline } from 'react-icons/hi';
import { RiComputerLine } from 'react-icons/ri';
import Utils from '../components/Utils';
// import Utils from '../components/Utils';

export default class Globals {}

Globals.SysAdminRole = '59a72bbd-9a43-427e-9c92-afd74ab19433';
Globals.WeekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
Globals.MaxStudentsBatchEnrol = 10;
//Comp.
Globals.CompabilityMessage =
  'You are using Internet Explorer to connect to this application. Some functions may not work correctly using IE. In order to have a better experience please use Chrome, Firefox or Safari.';
//UI
Globals.Table_PagingItemsPerPage = 25;
Globals.DefaultDateFormat = 'DD/MM/YYYY';
Globals.DefaultUIBirthDateFormat = 'YYYY-MM-DD';
Globals.DefaultUIDateTimeFormat = 'YYYY-MM-DD HH:mm';
Globals.COLOR_PALETTE = {
  main_red: '#af3947',
  light_red: '#e56972',
  dark_red: '#7a0020',
  light_grey: '#f0f2f5',
  grey: '#babcbf',
  green: '#4bb543',
  blue: '#03a9f4',
  light_blue: '#89CFF0',
  orange: '#ffae42',
};
//FE URL
Globals.URL_Path_ID_Placeholder = 'id';
Globals.URL_Path_ID2_Placeholder = 'id2';
Globals.URL_Path_ID3_Placeholder = 'id3';
Globals.URL_Path_LicensePurchase_OrderID = 'orderID';
Globals.URL_Path_LicensePurchase_TokenID = 'token-id';
Globals.URL_Path_LicensePurchase_ProductID = 'productID';
Globals.URL_Path_LicensePurchase_CustomID = 'customID';
Globals.URL_Path_TabID = 'tab';
Globals.URL_Path_Affiliate_ID = 'affiliateID';
Globals.URL_Path_SearchTerm = 'search';
Globals.URL_Path_Page = 'page';
Globals.URL_Path_Filters = 'filters';
Globals.URL_Path_SortField = 'sort';
Globals.URL_Path_SortOrder = 'order';
Globals.URL_Path_Status = 'status';
Globals.URL_Path_Certification = 'certification';
Globals.URL_Path_Course = 'selectedCourses';
Globals.URL_Path_City = 'selectedCities';
Globals.URL_Path_Cost = 'selectedCost';
Globals.URL_Path_Type = 'selectedSessionTypes';
Globals.URL_Path_SigninDate1 = 'signinDate1';
Globals.URL_Path_SigninDate2 = 'signinDate2';
Globals.URL_Path_LicensePurchase_SessionID = 'sessionID';
Globals.URL_NewIndentifier = 'new';
Globals.URLQueryParam_Type = 'type';
Globals.URLQueryParam_From = 'from';
Globals.URLQueryParam_To = 'to';
Globals.URLQueryParam_CertID = 'certID';
Globals.URLQueryParam_Launch = 'launch';
Globals.URLQueryParam_CourseID = 'courseID';
Globals.URLQueryParam_InvitationCode = 'invitationCode';
//UI Prefs and Labels
Globals.FLOAT_DECIMALS = 2;
Globals.ERROR_MESSAGE_DEFAULT = 'Unable to load user data, if this error happens again, please contact support.';
Globals.LABEL_CERTIFICATION_DESCRIPTION = 'Description';
Globals.LABEL_COMMENTS = 'Comments';
Globals.LABEL_ADDRESS_SECTION = 'Address';
Globals.LABEL_SUITE = 'Suite';
Globals.LABEL_STREET = 'Street Address';
Globals.LABEL_STREET2 = 'Street Address 2';
Globals.LABEL_STREET_NUMBER = 'Street Number';
Globals.LABEL_CITY = 'City';
Globals.LABEL_PROVINCE = 'Province';
Globals.LABEL_POSTAL_CODE = 'Postal Code';
Globals.LABEL_COUNTRY = 'Country';
Globals.LABEL_MOBILE_PHONE = 'Mobile Number';
Globals.LABEL_NAME_ON_CERTIFICATE = 'Name on Certificate';
Globals.LABEL_OTHER_PHONE = 'Other Number';
Globals.LABEL_PERSONAL_SECTION = 'Personal Information';
Globals.LABEL_EMPLOYER = 'Employer';
Globals.LABEL_ITA_NUMBER = 'ITA Number';
Globals.LABEL_BIRTH_DATE = 'Date of Birth';
Globals.LABEL_AGREED_ON_TERMS = "I agree to {ORG_NAME}'s terms and privacy policy";
Globals.LABEL_READ_TERMS = "Read {ORG_NAME}'s terms of service";
Globals.LABEL_SEND_GIFT = 'Send Gift';

//API Definitions
Globals.API_MaxEpochDiscrepancy = 300000; //allow user to be X ms out of the server time
//Certification states
//Here we have a incomplete version of process states.. API has the complete version on IKCollegerGlobals.js
Globals.CertificationProcess_State = {
  //Please dont change this states before reflecting it on API!
  LICENSE_PENDING: 'LICENSE_PENDING',
  READY_SCHEDULE: 'READY_TO_SCHEDULE',
  SCHEDULED: 'SCHEDULED',
  LICENSE_ACTIVATED: 'LICENSE_ACTIVATED',
  COOLDOWN: 'COOLDOWN',
  RESCHEDULE_LICENSE_PENDING: 'RESCHEDULE_LICENSE_PENDING',
  EXPIRED_REQUIREMENT: 'EXPIRED_REQUIREMENT',
  EXPIRED: 'EXPIRED',
  FAILED: 'FAILED',
  COMPLETED: 'COMPLETED',
  APPLICATION_PENDING: 'APPLICATION_PENDING',
};
Globals.Course_State = {
  PENDING: 'PENDING',
  PASS: 'PASSED',
  FAIL: 'FAILED',
  CANCELLED: 'CANCELLED',
  REVOKED: 'REVOKED',
};
Globals.CertificationProcess_CertificationPrintType = {
  CERT: 'onlineCertificate',
  CARD: 'onlineCard',
};
Globals.LicenseOrder_ReceiptEnabled_Providers = {
  BRAINTREE: 'BRAINTREE',
};

//Card
Globals.getCertificationDisplayState = function (state, certSpecs) {
  const states = Globals.CertificationProcess_State;
  if (state) {
    switch (state) {
      case states.LICENSE_PENDING:
        return 'License Activation Pending';
      case states.READY_SCHEDULE:
        return 'Ready for Schedule';
      case states.SCHEDULED:
        return 'Scheduled';
      case states.LICENSE_ACTIVATED:
        return 'License Activated';
      case states.COOLDOWN:
        return 'Cooldown';
      case states.RESCHEDULE_LICENSE_PENDING:
        return 'Pending Reschedule Payment';
      case states.COMPLETED:
        return `${Utils.camelizeString(certSpecs.uiTerm)} Completed`;
      case states.FAILED:
        return `${Utils.camelizeString(certSpecs.uiTerm)} Failed`;
      case states.EXPIRED_REQUIREMENT:
        return 'Requirement Expired';
      case states.APPLICATION_PENDING:
        return 'Application Pending';
      case states.EXPIRED:
        return `${Utils.camelizeString(certSpecs.uiTerm)} Expired`;
      default:
        return state;
    }
  }
  return '';
};
Globals.getCertificationAction = function (state) {
  const states = Globals.CertificationProcess_State;
  if (state) {
    switch (state) {
      case states.PAYMENT_PENDING:
      case states.PAYMENT_FAILED:
        return 'Payment Required';
      case states.READY_SCHEDULE:
        return 'Ready to be Scheduled';
      case states.EXPIRED_REQUIREMENT:
        return 'Restart Required';
      case states.EXPIRED:
        return 'Renewal Required';
      default:
        return '';
    }
  }
  return '';
};
Globals.USER_FILTERS = [
  { key: 'ALL', label: 'All' },
  { key: 'REGISTERED', label: 'Registered' },
  { key: 'ACTIVE', label: 'InProgress' },
  { key: 'UNACTIVE', label: 'Inactive' },
  { key: 'CERTIFIED', label: 'Completed' },
  { key: 'EXPIRED', label: 'Expired Processes' },
  { key: 'EXPIRED_ONLINE', label: 'Expired Courses/Exams' },
  { key: 'APPLICATION_APPROVED', label: 'Approved Applications' },
  { key: 'APPLICATION_IN_PROGRESS', label: 'InProgress Applications' },
  { key: 'APPLICATION_REJECTED', label: 'Rejected Applications' },
  { key: 'ENROLLED_COURSE', label: 'Enrolled Course' },
  { key: 'RECERTIFIED', label: 'Recertified' },
];
Globals.REPORT_TYPES = {
  REVENUE_PERIOD: 'REVENUE_PERIOD',
  AFFILIATE_COMMISION: 'AFFILIATE_COMMISION',
  CUSTOM_USERS: 'CUSTOM_USERS',
  CERTIFICATION_PERIOD: 'CERTIFICATION_PERIOD',
  EXPIRED_CERTIFICATIONS: 'EXPIRED_CERTIFICATIONS',
  APPLICATIONS_EXPORT: 'APPLICATIONS_EXPORT',
  APPLICATION_STATUS: 'APPLICATION_STATUS',
  EXAM_ANALYSIS: 'EXAM_ANALYSIS',
  SESSIONS_LIST: 'SESSIONS_LIST',
  SESSIONS_RESULTS: 'SESSIONS_RESULTS',
};

//License Module
Globals.LicenseOrder_State = {
  INITIATED: 'INITIATED',
  FAILED: 'FAILED',
  DECLINED: 'DECLINED',
  CANCELLED: 'CANCELLED',
  REFUNDED: 'REFUNDED',
  //Invoice
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  //Common
  COMPLETED: 'COMPLETED',
};
Globals.LicenseOrder_Type = {
  GRANT: 'GRANT',
  CARD: 'CARD',
  INVOICE: 'INVOICE',
};
Globals.License_State = {
  ACTIVE: 'ACTIVE',
  EXPIRED: 'EXPIRED',
  REVOKED: 'REVOKED',
};
Globals.getColorByOrderState = function (state) {
  if (state == Globals.LicenseOrder_State.INITIATED) return 'gold';
  else if (state == Globals.LicenseOrder_State.PENDING) return 'orange';
  //
  else if (state == Globals.LicenseOrder_State.COMPLETED) return 'geekblue';
  //
  else if (state == Globals.LicenseOrder_State.FAILED) return 'red';
  else if (state == Globals.LicenseOrder_State.DECLINED) return 'red';
  else if (state == Globals.LicenseOrder_State.CANCELLED) return 'red';
  else if (state == Globals.LicenseOrder_State.REJECTED) return 'red';
  //
  else if (state == Globals.LicenseOrder_State.REFUNDED) return 'purple';
  return 'default';
};
Globals.getColorByOrderType = function (state) {
  if (state == Globals.LicenseOrder_Type.GRANT) return 'default';
  else if (state == Globals.LicenseOrder_Type.CARD) return 'default';
  else if (state == Globals.LicenseOrder_Type.INVOICE) return 'default';
  return 'default';
};
Globals.getIconByOrderType = function (state) {
  if (state == Globals.LicenseOrder_Type.GRANT) return <GiftOutlined />;
  else if (state == Globals.LicenseOrder_Type.CARD) return <CreditCardOutlined />;
  else if (state == Globals.LicenseOrder_Type.INVOICE) return <ScheduleOutlined />;
  return <></>;
};
Globals.getColorByLicenseStatus = function (state) {
  if (state == Globals.License_State.ACTIVE) return 'green';
  else if (state == Globals.License_State.EXPIRED) return 'volcano';
  else if (state == Globals.License_State.REVOKED) return 'red';
  return 'default';
};
Globals.getTemplateTypeIcon = function (type, includeLabel) {
  if (type == Globals.Session_Type.SCHEDULED_ONLINE_EXT)
    return (
      <>
        <CalendarOutlined style={{ marginRight: 7 }} />
        {includeLabel && 'Online External (Scheduled)'}
      </>
    );
  else if (type == Globals.Session_Type.SCHEDULED_PRESENTIAL)
    return (
      <>
        <FaBuilding style={{ marginRight: 7 }} />
        {includeLabel && 'In class (Scheduled)'}
      </>
    );
  else if (type == Globals.Session_Type.SCHEDULED_WEBINAR)
    return (
      <>
        <GiVideoConference style={{ marginRight: 7 }} />
        {includeLabel && 'Webinar'}
      </>
    );
  else if (type == Globals.Session_Type.ONLINE_EXT)
    return (
      <>
        <HiOutlineStatusOnline style={{ marginRight: 7 }} />
        {includeLabel && 'Online External'}
      </>
    );
  else if (type == Globals.Session_Type.ONLINE)
    return (
      <>
        <RiComputerLine style={{ marginRight: 7 }} />
        {includeLabel && 'Online'}
      </>
    );
  else if (type == Globals.Session_Type.SCHEDULED_VILT)
    return (
      <>
        <ContactsOutlined style={{ marginRight: 7 }} />
        {includeLabel && 'VILT'}
      </>
    );
  return '-';
};
Globals.getTemplateTypeLabel = function (type) {
  if (type == Globals.Session_Type.SCHEDULED_ONLINE_EXT) return 'Online External (Scheduled)';
  else if (type == Globals.Session_Type.SCHEDULED_PRESENTIAL) return 'In class (Scheduled)';
  else if (type == Globals.Session_Type.SCHEDULED_VILT) return 'VILT';
  else if (type == Globals.Session_Type.SCHEDULED_WEBINAR) return 'Webinar';
  else if (type == Globals.Session_Type.ONLINE_EXT) return 'Online External';
  else if (type == Globals.Session_Type.ONLINE) return 'Online';
  return '-';
};
Globals.getColorBySessionState = function (state) {
  if (state == Globals.Session_State.DRAFT) return 'blue';
  if (state == Globals.Session_State.AVAILABLE) return 'green';
  if (state == Globals.Session_State.PENDING_ADMIN) return 'gold';
  if (state == Globals.Session_State.PENDING_INSTRUCTOR) return 'orange';
  if (state == Globals.Session_State.COMPLETED) return 'geekblue';
  if (state == Globals.Session_State.CANCELLED) return 'red';
  if (state == Globals.Session_State.LOCKED) return 'orange';
  return 'default';
};
Globals.getTextBySessionState = function (state) {
  if (state == Globals.Session_State.DRAFT) return 'Draft';
  if (state == Globals.Session_State.AVAILABLE) return 'Available';
  if (state == Globals.Session_State.PENDING_ADMIN) return 'Pending Admin Review';
  if (state == Globals.Session_State.PENDING_INSTRUCTOR) return 'Pending Instructor Review';
  if (state == Globals.Session_State.COMPLETED) return 'Completed';
  if (state == Globals.Session_State.CANCELLED) return 'Cancelled';
  if (state == Globals.Session_State.LOCKED) return 'Locked';
  return '-';
};
//
Globals.selectedOrgStorageKey = '@SRBC:SOID';
Globals.LICENSES_PRICE_ABOVE = 999999;

// Course Card Statuses
Globals.CourseStatuses = {
  DEFAULT: 'DEFAULT',
  PAYMENT_REQUIRED: 'PAYMENT_REQUIRED',
  COURSE_AVAILABLE: 'COURSE_AVAILABLE',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  COOLDOWN: 'COOLDOWN',
  LOCKED: 'LOCKED',
  EXPIRED: 'EXPIRED',
  REVOKED: 'REVOKED',
  SCHEDULED: 'SCHEDULED',
  READY_TO_SCHEDULE: 'READY_TO_SCHEDULE',
  MISSING_REQUIREMENTS: 'MISSING_REQUIREMENTS',
  WAITING_RESULT_RELEASE: 'WAITING_RESULT_RELEASE',
  INVALIDATED: 'INVALIDATED',
  ACCESS_DENIED: 'ACCESS_DENIED',
  CHECKING_STATUS: 'CHECKING_STATUS',
};

Globals.PurchasableCourseStatuses = [
  Globals.CourseStatuses.PAYMENT_REQUIRED,
  Globals.CourseStatuses.FAILED,
  Globals.CourseStatuses.EXPIRED,
  Globals.CourseStatuses.REVOKED,
  Globals.CourseStatuses.INVALIDATED,
];

Globals.PendingApprovalType = {
  COURSE_FILE_UPLOAD: 'COURSE_FILE_UPLOAD',
};

Globals.CommonCertificationViewTabs = {
  NEW_APPLICATION: 0,
  COURSE: 1,
  RESULT: 2,
  COOLDOWN: 3,
  RENEW_APPLICATION: 4,
};

Globals.CourseTabCourseCardTabs = {
  OVERALL: 0,
  PAYMENTS: 1,
  RESULTS: 2,
  RESOURCES: 3,
  DOWNLOAD: 4,
  UPLOAD: 5,
  SESSION: 6,
};

Globals.CourseApprovalState = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

Globals.ApplicationTypes = {
  NEW: 'NEW',
  RENEWAL: 'RENEWAL',
};

Globals.Providers_Types = {
  EXTERNAL: 'EXTERNAL',
  INTERNAL: 'INTERNAL',
  FILE: 'FILE',
};

Globals.ApplicationItemsStatus = {
  DEFAULT: 'default',
  HISTORIC: 'historic',
  UPLOADED: 'uploaded',
  APPROVED: 'approved',
  REJECTED: 'rejected',
};

Globals.ApplicationState = {
  PENDING: 'PENDING',
  PRE_APPROVAL: 'PRE_APPROVAL',
  WAITING_APPROVAL: 'WAITING_APPROVAL',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

Globals.Certification_BannerTypes = {
  WARNING: 'WARNING',
  INFO: 'INFO',
  CRITICAL: 'CRITICAL',
};
Globals.Certification_State = {
  CERTIFIED: 'Certified',
  RECERTIFIED: 'Recertified',
};
Globals.CourseProvider_Type = {
  D2L: 'D2L',
  TCP: 'TCP',
};
Globals.Session_Type = {
  SCHEDULED_PRESENTIAL: 'SCHEDULED_PRESENTIAL',
  SCHEDULED_ONLINE_EXT: 'SCHEDULED_ONLINE_EXT',
  SCHEDULED_VILT: 'SCHEDULED_VILT',
  SCHEDULED_WEBINAR: 'SCHEDULED_WEBINAR',
  ONLINE_EXT: 'ONLINE_EXT',
  ONLINE: 'ONLINE',
};
Globals.Session_ReleaseType = {
  NONE: 'NONE', //online access, direct enroll >> direct released >> access is enabled from purchase
  AUTO: 'AUTO', //scheduled only, direct enroll >> on demand release >> access is enabled only within the event range
  MANUAL: 'MANUAL', //all types, direct enroll >> on action release
  SCHEDULE: 'SCHEDULE', //all types, follows launchSchedule
};
Globals.SessionEnrolment_AccessRelease = {
  RELEASED: 'RELEASED',
  LOCKED: 'LOCKED',
  AUTO: 'AUTO',
  SCHEDULE: 'SCHEDULE',
};
Globals.Session_HasMaterial = {
  NONE: 'NONE',
  INDIVIDUAL: 'INDIVIDUAL',
  ALL: 'ALL',
};
Globals.Session_State = {
  DRAFT: 'DRAFT',
  PENDING_INSTRUCTOR: 'PENDING_INSTRUCTOR',
  AVAILABLE: 'AVAILABLE',
  PENDING_ADMIN: 'PENDING_ADMIN',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
  LOCKED: 'LOCKED',
};
Globals.Attendance_Status = {
  INCOMPLETE: 'INCOMPLETE',
  NO_SHOW: 'NO_SHOW',
  ATTENDED: 'ATTENDED',
};
Globals.Result_Status = {
  INCOMPLETE: 'INCOMPLETE',
  FAIL: 'FAIL',
  PASS: 'PASS',
};
Globals.PromotionTypes = {
  AFTER_FIRST_PURCHASE: 'AFTER_FIRST_PURCHASE',
};

Globals.Cert_UiLogo = {
  bccsla: 'bccsla',
  dust: 'dust',
  coria: 'coria',
  hma: 'hma',
  lse: 'lse',
  mmw: 'mmw',
  ncso: 'ncso',
  nhsa: 'nhsa',
  pim: 'dupimst',
  phsm: 'phsm',
  tst: 'tst',
  whmis: 'whmis',
  asbestosL1: 'asbestosL1',
  asbestosL2: 'asbestosL2',
  asbestosL3: 'asbestosL3',
  asbestosLS: 'asbestosLS',
};

Globals.Certification_BannerType = {
  WARNING: 'WARNING',
  INFO: 'INFO',
  CRITICAL: 'CRITICAL',
};

Globals.Certification_UiTerm = {
  certification: 'certification',
  webinar: 'webinar',
  course: 'course',
  process: 'process',
  exam: 'exam',
  assessment: 'assessment',
};

Globals.Certification_CertTypes = {
  onlineCertificate: 'onlineCertificate',
  card: 'card',
  none: 'none',
};

Globals.Certification_CertTemplates = {
  srbc: 'srbc',
  srmb: 'srmb',
  ncsobc: 'ncsobc',
  nhsabc: 'nhsabc',
  phsm: 'phsm',
  hma: 'hma',
  dustbc: 'dustbc',
  asbestosbc: 'asbestosbc',
  trainingbc: 'trainingbc',
  trainingbcexp: 'trainingbcexp',
  mmwbc: 'mmwbc',
  coriacertification: 'coriacertification',
};

Globals.CertificationSectionWarning_Type = {
  success: 'success',
  info: 'info',
  error: 'error',
  warning: 'warning',
};

Globals.Course_UiLogo = {
  asbestosbc: 'asbestosbc',
  bccsla: 'bccsla',
  'coria-logo': 'coria-logo',
  default: 'default',
  dust: 'dust',
  hma: 'hma',
  lse: 'lse',
  mmw: 'mmw',
  ncso: 'ncso',
  nhsa: 'nhsa',
  'ncso-provincial': 'ncso-provincial',
  'ncso-national': 'ncso-national',
  'nhsa-provincial': 'nhsa-provincial',
  'nhsa-national': 'nhsa-national',
  phsm: 'phsm',
  pim: 'pim',
  sitereadybc: 'sitereadybc',
  sitereadymb: 'sitereadymb',
  tst: 'tst',
  whmis: 'whmis',
};

Globals.Course_Types = {
  COURSE: 'Course',
  EXTERNALREQUIREMENT: 'External Requirement',
  ASSIGNMENT: 'Assignment',
};

Globals.Course_Types_Keys = {
  COURSE: 'COURSE',
  EXTERNALREQUIREMENT: 'EXTERNALREQUIREMENT',
  ASSIGNMENT: 'ASSIGNMENT',
};

Globals.SessionEnrolment_ChargeResult = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
};

Globals.Session_Cost = {
  ALL: 'All',
  ELIGIBLE_FOR_NO_COST: 'Eligible for no cost',
};

Globals.Tenant_DisablableEmails = [
  // List extracted from Program-API on Sept 18, 2024
  'certificationApplicationSubmitted_Admin',
  'certificationApplicationApproved',
  'certificationApplicationPreApprovalRequested',
  'certificationApplicationPreApprovalRequested_Admin',
  'certificationApplicationPreApprovalReviewed',
  'certificationApplicationRejected',
  'certificationApplicationRenewStarted',
  'certificationApplicationStarted',
  'certificationApplicationSubmitted',
  'certificationCompleted',
  'certificationCompletedManager',
  'certificationExpire1st',
  'certificationExpire1st_Admin',
  'certificationExpire2nd',
  'certificationExpire2nd_Admin',
  'certificationFailed',
  'certificationRenewStarted',
  'certificationRestarted',
  'certificationStarted',
  'contactForm',
  'courseAdminRevoked',
  'courseApprovalApproved',
  'courseApprovalRejected',
  'courseApprovalRequested',
  'courseApprovalRequested_Admin',
  'courseCooldownFinished',
  'courseCooldownOverwritten',
  'courseCooldownStarted',
  'courseExpirationWarning',
  'courseExpirationWarningManager',
  'courseExpired',
  'courseExpiredManager',
  'courseFailed',
  'courseFailedManager',
  'courseNoShow',
  'courseNoShowManager',
  'coursePassed',
  'coursePassedManager',
  'courseRedeemSucceeded',
  'courseRevoked',
  'importProcess',
  'sessionAutoEnrolmentFailed',
  'userRegistration',
  'userRegistrationInvalidReferrer',
  // List extracted from Classroom-API on Sep 18, 2024
  'onlineExpire_30d',
  'onlineExpire1st',
  'onlineExpire2nd',
  'onlineExpire3rd',
  'sessionAssignedToInstructor',
  'sessionBillingSummary',
  'sessionCancelled',
  'sessionEventReminder1d',
  'sessionEventReminder7d',
  'sessionFull',
  'sessionInvitation',
  'sessionNoLongerFull',
  'sessionPendingAdminReview',
  'sessionPendingInstructorReview',
  'sessionPendingInstructorReview_Admin',
  'sessionUnassignedFromInstructor',
  'sessionVenueChanged',
  'studentEnrolmentCanceled',
  'studentEnrolmentDeleted',
  'studentEnrolmentNotification',
  'studentEnrolmentNotification_Admin',
];

//Calendar View
Globals.CalendarViewStartTime = '06:00';
Globals.CalendarViewEndTime = '23:00';
Globals.CalendarViewHasWeekends = true;

//Vaulting
Globals.Cookies_NonceKey = 'lms_nonce_v1';
Globals.Cookies_NonceCachingPeriod = 24 * 60 * 60 * 1000; //1 day

//Org module integration
Globals.RegOrgEmploymentSelectionType = 'employmentSelection';
Globals.PortalFEPath_OrgCreate = '/app/org/new';
Globals.PortalFEPath_UserProfile = '/user';
Globals.PortalFEPath_CustomUser = '/app/idm/user/';
